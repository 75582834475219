// Variables
// -----------------------------------------------------
$theme-color: #243383; 
$theme-color-pale: rgb(204, 207, 213);
$light-border: 0.5px solid #eceef3;
$text-light: rgb(230, 230, 230);
$light-grey-background-color: #f0f0f0;

// Paste from Theme.ts
// -----------------------------------------------------
$primary_dark:#243657;
$primary_main:#314A94;
$primary_light:#6D8DC1;
$primary_contrastText:#ffffff;

$secondary_dark:#D67D11;
$secondary_main:#FF910A;
$secondary_light:#FFAC47;
$secondary_contrastText:#ffffff;

$info_dark:#585c67;
$info_main:#6e7482;
$info_light:#8d9098;
$info_contrastText:#ffffff;

$error_main:#c76466;
$error_dark:#c24b4e;
$error_light:#e87a7d;
$error_contrastText:#ffffff;

$success_dark:#548755;
$success_main:#6fa871;
$success_light:#8eba8f;
$success_contrastText:#ffffff; 

$warning_dark:#D67D11;
$warning_main:#FF910A;
$warning_light:#FFAC47;
$warning_contrastText:#ffffff;
// -----------------------------------------------------
// Paste from Theme.ts


// General and miscellaneous
// -----------------------------------------------------
body {
    overflow: hidden !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #191919;
    font-size: 14px;
    // background-color: #25334e;
}
*::selection {
    background-color: #c4c4c47d;
}
*{
    cursor:default;
}
// code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }
.error-boundary {
    padding: 8px;
    font-family: monospace;
    // background-color: rgb(233, 233, 233);
    white-space: pre;
    // overflow: scroll;
    // overflow-wrap: scroll;
    color: grey;
}
.App{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
hr {
    border: 0.1px solid #ebebeb;
    width: 100%;
    margin: 0;
    z-index: 100;
}
.capitalise {
    text-transform: capitalize;
}
.container{
    padding-top: 30px;
    box-sizing: border-box;
}
h1,h2,h3,h4,h5,h6 {    
    margin: 0;    
}
h1,h2,h3,h4,h5,h6, thead td{
    color: #191919;
    font-weight: 600;
}
p {
    margin: 5px 0;
}
p, td {
    color: #191919;
    font-size: 12px
}
label {
    cursor: pointer !important;
}
.trunc-ellipsis {
    width: auto;
    max-width: max-content;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
.trunc-measurer {
    opacity: 0;
    top: -1000;
    z-index: -100;
    position: fixed;
    width: fit-content;
}
.trunc-revealer {
    width: min-content;
    max-width: 300px;
    min-width: 170px;
    position: fixed;
    z-index: 1000;
    padding: 3px 5px;
    font-size: 10px;
    word-wrap: normal;
    background: white;
    color: rgb(34, 34, 34);
    box-shadow: 2px 2px 20px 0 #0000004f;
    transition: all 0s;
    white-space: normal;
}
small {
    font-size: 10px;
    display: block;
    svg {
        font-size: 12px !important;
    }
}
.bold{
    font-weight: 500;
}
a{
    cursor: pointer !important;
    color: inherit;
    text-decoration: none;
}
svg {
    cursor: inherit;
}
.disabled {
    pointer-events: none;
    // visibility: hidden;
    * {
        opacity: 0.75;
    }
}
.MuiCircularProgress-root {
    margin: auto;
}
.MuiLinearProgress-root {
    width: 100%;
}
.MuiList-root {
    padding: 0 !important;
}
.MuiMenuItem-root {
    font-size: 14px !important;
}

.center-h{
    margin-left: auto;
    margin-right: auto;    
}
.header, .footer{
    width: auto;
    display: flex;
    align-items: baseline;
    justify-content: space-between; 
    gap: 15px;
    // margin-bottom: 20px;
    h1,h2,h3,h4,h5,h6{
        margin: 0;
    }
}
.shadow {
    box-shadow: 0 0 10px 0 #0000001f;
}
.link {
    color: $primary_main !important;
    text-decoration: underline;
    cursor: pointer;
}
b {
    font-weight: 500;
}
// Alert styles
.SnackbarContent-root{
    backdrop-filter: blur(5px) !important;
    .SnackbarItem-action {
        margin: 0 0 0 auto !important;
        button {
            margin-left: 8px;
        }
    }
}
.SnackbarItem-variantInfo {
    background-color: #ffffffd9 !important;
    color: black !important;
    svg {
        color: #444444 !important;
    }
}
.SnackbarItem-variantError {
    background-color: $error_main + d9 !important;
    color: #fff !important;
}
.SnackbarItem-variantSuccess {
    background-color: $success_main + d9 !important;
    color: #fff !important;
}

.status-indicator {
    width: auto !important; 
    height: fit-content !important;
    display: flex !important;
    align-items: center;
    white-space: nowrap;
    border-radius: 20px;
    color: $info_main;
    font-size: 10.5px;
    font-weight: 600;
    button {
        padding: 0 !important;
        margin-right: 4px;
        color: inherit;
    }
}


.ml-auto{
    margin-left: auto !important;
}
.mr-auto{
    margin-right: auto !important;
}
.m-auto{
    margin: auto !important;
}


// Font
// -----------------------------------------------------
.font-grey, .font-grey * {
    color: grey !important;
}
.font-xs{
    font-size: 12px !important;
    display: flex;
    align-items: center;
}
.font-sm{
    font-size: 14px !important;
    display: flex;
    align-items: center;
}
.font-md{
    font-size: 16px !important;
    display: flex;
    align-items: center;
}

// Animations
// -----------------------------------------------------
.shimmer {
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes shimmer {
    100% {-webkit-mask-position:left}
}


// Scrollbar
// -----------------------------------------------------
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: pointer !important;
}
::-webkit-scrollbar-track {
    background: rgba(119, 119, 119, 0.107);
    border-radius: inherit;
    // box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.145) inset;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(196, 196, 196);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgb(168, 168, 168);
}