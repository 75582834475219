// Panel class
// -----------------------------------------------------
.panel-width-0, .panel-width-1, .panel-width-2, .panel-width-3, .panel-width-4, .panel-width-5, .panel-width-6, .panel-width-7, .panel-width-8, .panel-width-9 {
    .panel-columns {
        grid-template-columns: 100% !important;
    }
}
.panel{
    width: 100% !important;
    // height: 100%;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 0 6px 0 #00000026;
    border-top: 0.5px solid #00000033;
    z-index: 5;
    transition: all 0.5s;
    .loading-placeholder {
        width: 100%;
    }
    .panel-child-hidden {
        opacity: 0;
        z-index: -1000;
        position: relative;
        transform-origin: 0 0;
        transform: scale(0);
        pointer-events: none;
    }
    .panel-label {
        opacity: 1 !important;
    }
    .panel-label {
        width: 33.5vw;
        height: 27px;
        padding: 0 14px 0;
        transform-origin: left;
        z-index: 100;
        display: none;
        align-items: center;
        font-size: 10px;
        align-items: center;
        position: absolute;
        background-color: white;
        border-right: 0.5px solid rgba(120, 120, 120, 0.507);
        border-top-right-radius: 5px;
        box-shadow: 0 4px 0px 0 white;
        box-sizing: border-box;
        cursor: pointer;
        * {
            cursor: pointer;
        }
        .collapse-btn {
            margin-left: auto;
            padding: 0 !important ;

        }
        .panel-label-arrow {
            font-size: 16px !important;
        }
        .label-content {
            width: -webkit-fill-available;
            display: flex;
            align-content: start;
            white-space: nowrap;
        }
    }
    .panel-header {
        width: 100%;
        min-height: 55px;
        max-height: 55px;
        padding: 0 14px 0px 14px;
        box-sizing: border-box;
        display: grid;
        align-items: end;
        background-color: white;
        // border-top: 0.5px solid rgba(156, 156, 156, 0.445);
        .panel-header-node-type {
            color: grey;
            display: flex;
            align-items: center;
            gap: 4px;
        }
        .panel-header-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            .loading-placeholder {
                max-height: 35px;
            }
            h4 {
                color: #191919;
                font-size: 14px !important;
            }
            .quick-action:hover {
                color: $primary_main;
            }
        }
    }
    .panel-body{
        // height: 100%;
        height: -webkit-fill-available;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10px 30px 14px;
        display: flex;
        flex-direction: column;
        box-shadow: none !important;
        background-color: white;
        table{
            width: 100%;
            td{
                font-size: 14px;
                padding: 2px;
                color: grey;
            }
        }
        .panel-columns {
            display: grid;
            grid-template-columns: 49% 49%;
            grid-column-gap: 2%;
            margin-top: 10px;
            .panel-column {
                height: fit-content;
                display: grid;
                grid-gap: 15px;
            }
        }
    }
    nav{
        width: 100%;
        overflow-x: clip;
        list-style: none;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        .list-grouping-header{
            font-size: 12px;
            font-weight: 700;
            color: rgb(80, 80, 80);
            margin: 10px 0 5px 0;
        }
        .selected {
            color: $primary_main !important;
            font-weight: 400;
            .type-icon {
                color: $primary_main !important;
            }
        }
        .state-subject {
            * {
                color: black;
            }
        }
        .panel-list-item:not(:last-of-type) {
            border-bottom: 0.5px solid #eceef3;
        }
        .panel-list-item{
            height: 46px;
            box-sizing: content-box;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: all 0.5s;
            cursor: pointer;
            * {
                cursor: pointer;
                transition: all 0.5s, color 0.1s !important;
            }
            svg{
                font-size: 20px;
                color: rgb(171, 171, 171);
            }
            label {
                width: 100%;
                display: flex;
                overflow: hidden;
                padding-left: 8px;
                justify-content: space-between;
                align-items: center;
            }
        }
        .panel-list-item:hover{
            svg:first-child{
                margin-left: 4px;
            }
        }
        .panel-list-item:focus{
            // background-color: whitesmoke;
            // margin-left: 4px;
            box-shadow: 0 -8px 8px -8px #0000001b, 0 8px 8px -8px #0000001b;
            outline: none;
        }
    }
}


// Minimised panel
// -----------------------------------------------------
.panel-minimised {
    max-width: 0px !important;
    overflow: visible;
    position: absolute;
    .panel-label {
        display: flex !important;
        background-color: white;
        border-bottom: 0.5px solid rgba(173, 173, 173, 0.85);
        .panel-label-arrow {
            transform: rotateZ(90deg);
        }
    }
    .panel-header {
        border: none !important;
        visibility: collapse;
    }
    .panel-body {
        visibility: collapse;
    }
}

.panel-hidden {
    width: 0 !important;
    visibility: collapse;
}


// Drawer
// -----------------------------------------------------
.tool-drawer-open {
    .tool-drawer-body {
        max-width: 100% !important;
    }
}
.tool-drawer {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 270px auto;
    transition: grid-template-columns 0.5s ease-in-out;
    .tool-drawer-body {
        width: 100%;
        max-width: 0px;
        display: flex;
        padding-top: 5px;
        overflow: hidden;
        background-color: rgb(45, 45, 45);
        transition: all 0.5s ease-in-out;
        box-sizing: border-box;
        .panel {
            box-shadow: none;
            color: white;
            border: none;
            hr {
                border-color: transparent;
            }
            p {
                font-size: 14px;
            }
            .panel-header, .panel-body {
                background-color: rgb(45, 45, 45);
                border: none !important;
            }
        }
        .link, a, p, h1, h2, h3, h4, h5, h6 {
            color: rgb(232, 232, 232) !important;
        }
        .tool-section {
            margin: 20px 0;
            display: grid;
            gap: 10px;
        }
    }
}
@media only screen and (max-width: 800px) {
    .tool-drawer-open {
        grid-template-columns: 0 auto;
        .tool-drawer-body {
            max-width: 100% !important;
            .panel-header {
                max-height: none;
                .panel-header-content {
                    max-height: unset;
                    align-items: flex-start;
                    .header {
                        flex-direction: column;
                        gap: 10px;
                    }
                }
            }
        }
    }
}


// Question progress
// -----------------------------------------------------
.panel-question{
    z-index: 7;
    .panel-label {
        p, svg{
            font-size: 12px;
            margin: 0;
        }
    }
    .panel-body {
        padding-top: 16px;
        .question {
            display: grid !important;
            row-gap: 12px !important;
        }
    }
}


// Folder panel
// -----------------------------------------------------
.panel-folder{
    z-index: 10;
}


@media only screen and (max-width: 600px) {
    .panel {
        width: 100vw;
        margin-top: 25px;
        z-index: 10;
        position: absolute;
    }
    .panel-label {
        display: flex !important;
        top: -26px;
        z-index: inherit;
    }
    .panel-minimised {
        position: absolute;
        z-index: 0;
        box-shadow: none;
        .panel-label {    
            border-top-right-radius: 0px !important;
        }
        .panel-header {
            border: none !important;
            visibility: visible;
        }
        .panel-body {
            visibility: visible;
        }
    }
}